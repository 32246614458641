
  import {
  defineComponent,
  reactive,
  onMounted,
  toRefs,
  getCurrentInstance,
  ref,
  inject,
  } from "vue";

  import { getLoadingList, getLoadingDetail } from "../../https/api";
  import DiliPagination from "../../components/DiliPagination.vue";
//引入ts接口

  interface contentData {
    // 列表
    tableData: Array<any>;
    tableColumns: Array<any>;
    
    // 分页
    total: number;
    tableLoading: boolean;

    // 弹框
    modalDetailFormData: any;
    modalDetailVisible: boolean;
    modalLoadingItemColumns: Array<any>;
  }
  export default defineComponent({
    name: "loading",
    setup() {
      const { proxy }: any = getCurrentInstance(); 
      const moment: any = inject('moment');
      const searchFormRef = ref();
        
      const searchForm = reactive({
        searchData:  {
          state: '0', 
          keyword: 'customName',
          keyvalue: '',
          orderTime: [], 
          serveTime: [], 
          page: 1,
          rows: 10
        }
      })
      const data: contentData = reactive({
        // 搜索
        // 列表
        tableData: [],
        tableColumns: [
          {
            title: '下单时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 120,
          },
          {
            title: '客户姓名',
            dataIndex: 'customName',
            key: 'customName',
            width: 120,
          },
          {
            title: '客户手机号',
            dataIndex: 'customPhone',
            key: 'customPhone',
            width: 120,
            customRender: ({text}: any) => {
              return (text.substr(0, 3) + '****' +text.substr(7, 4))
            }
          },
          {
            title: '物流装卸单编号',
            dataIndex: 'code',
            key: 'code',
            width: 120,
          },
          {
            title: '服务时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 120,
          },
          {
            title: '服务类型',
            dataIndex: 'serviceType',
            key: 'serviceType',
            width: 120,
            customRender: ({text}: any) => {
              if(text == 1){
                return "装货"
              } else if(text == 2){
                return "卸货"
              }
            }
          },
          {
            title: '报价金额',
            dataIndex: 'offer',
            key: 'offer',
            width: 120,
          },
          {
            title: '需求地址',
            dataIndex: 'address',
            key: 'address',
            width: 120,
          },
          {
            title: '状态',
            dataIndex: 'state',
            width: 120,
            slots: { customRender: 'state' },
          },
          {
            title: '操作',
            dataIndex: 'action',
            slots: { customRender: 'action' },
            width: 200,
          },
        ],

        //分页数据
        total: 0,
        tableLoading: false,
        
        // 弹框详情
        modalDetailVisible: false,
        modalDetailFormData: {},
        modalLoadingItemColumns:[
          {
            title: '货物',
            dataIndex: 'goodsName',
            key: 'goodsName',
            width: 120,
          },
          {
            title: '件数',
            dataIndex: 'number',
            key: 'number',
            width: 120,
          },
          {
            title: '件重（KG）',
            dataIndex: 'unitWeight',
            key: 'unitWeight',
            width: 120,
          },
          {
            title: '总重（KG）',
            dataIndex: 'totalWeight',
            key: 'totalWeight',
            width: 120,
          },
        ],
      });
      
      /* -----------------------搜索----------------------- */
      // 关键字自定义校验
      const validateKeyvalue = async (rule: any, value: any) => {
        switch(searchForm.searchData.keyword) {
          case 'customName':
            if (value.length > 50){
              return Promise.reject('限50个字符');
            }
            break;
          case 'shipperCellphone':
            if (value.length > 11){
              return Promise.reject('限11个数字');
            }
            break;
          case 'code':
            if (value.length > 20){
              return Promise.reject('限20个字符');
            }
            break;
          default:
            return Promise.resolve();
        }
      };
      const rules = {
        keyvalue: [{ validator: validateKeyvalue, trigger: 'change' }],
      };
      // 搜索&重置
      const resetSearchForm = () => {
        // 由于没有找到清空组合框的方法，暂时只能手动赋值为空
        searchForm.searchData.keyvalue = '';
        searchFormRef.value.resetFields();
      };
      const search = () => {
        searchFormRef.value.validate()
        .then(() => {
          getLoadingTable();
        })
        .catch((error: any) => {
          console.log('error', error);
        });
      };
      // 获取装卸单列表
      const getLoadingTable = (): any=> {
        data.tableLoading = true;
        const params = {
          page: searchForm.searchData.page,
          rows: searchForm.searchData.rows,
          state: searchForm.searchData.state, 
          keyword: searchForm.searchData.keyword, 
          keyvalue: searchForm.searchData.keyvalue,
          orderTime:  searchForm.searchData.orderTime?.join('-'),
          serveTime: searchForm.searchData.serveTime?.join('-'),
        };
        getLoadingList(params).then((res: any) => {
          data.tableLoading = false;
          if (res.success) {
            data.tableData = res.data.rows;
            data.total = res.data.total;
          } else {
            proxy.$message.error(res.message);
          }
        });
      };

      /* -----------------------分页----------------------- */
       //分页变化
      const change = (current: number) => {
        searchForm.searchData.page = current;
        getLoadingTable();
      };
      const showSizeChange = (current: number, size: number) => {
        searchForm.searchData.page = current;
        searchForm.searchData.rows = size;
        getLoadingTable();
      };
       //当前显示条数变化
      onMounted(() => {
        getLoadingTable();
      });

      /* -----------------------弹框----------------------- */
      // 查看详情
      const openDetail = (record: any) => {
        const params = {
          id: record.id,
        };
        getLoadingDetail(params).then((res: any) => {
          if (res.success) {
            data.modalDetailVisible = true;
            const resData = res.data
            if(resData.shipperSex == 1) {
              resData.shipperName = resData.shipperName.substr(0,1) + '先生'
            } else {
              resData.shipperName = resData.shipperName.substr(0,1) + '女士'
            }
            switch(resData.state) {
              case 1:
                resData.state = '待接单';
                break;
              case 2: 
                resData.state = '已接单';
                break;
              case 3: 
                resData.state = '已完成';
                break;
              case 4: 
                resData.state = '已失效';
                break;
              case 5: 
                resData.state = '已取消';
                break;
            }
            switch(resData.serviceType) {
              case 1:
                resData.serviceType = '装货';
                break;
              case 2: 
                resData.serviceType = '卸货';
                break;
            }
            if(resData.shipperCellphone) {
              resData.shipperCellphone = resData.shipperCellphone.substr(0, 3) + '****' +resData.shipperCellphone.substr(7, 4);
            }
            if(resData.deliveryCellphone) {
              resData.deliveryCellphone = resData.deliveryCellphone.substr(0, 3) + '****' +resData.deliveryCellphone.substr(7, 4)
            }
            data.modalDetailFormData = resData;
          } else {
            proxy.$message.error(res.message);
          }
        });
      };
      const handleDetailCancel = () => {
        data.modalDetailVisible = false;
      };


      return {
        ...toRefs(searchForm),
        ...toRefs(data),
        rules,
        search,
        resetSearchForm,
        searchFormRef,
        moment,
        proxy,
        change,
        showSizeChange,
        openDetail,
        handleDetailCancel,
      };
    },
    components: {
      DiliPagination,
    },
  })

